/* General styling */
body {
    font-size: 16px;
}
ol {
    padding: 0;
}

main {
    width: 95%;
    margin: auto;
    text-align: center;
    list-style-position: inside;
}

header, footer {
    text-align: center;
}

/* Universal classes */
.black-text {
    color: black;
}

.hide {
    display: none;
}

.reveal {
    display: initial;
    opacity: 0;
}



/* Specific parts */
.loading {
    > * {
        display: none;
    }
    #loading {
        display: initial;
        font-size: 2em;
    }
}

#slogans ol, #editor-current-slogan ol {
    display: flex;
    flex-direction: column;
    gap: 15px;
    > * {
        width: 100%;
        min-height: 5rem;
        
        /* TODO: better centering solution? */
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        $border: 1px groove rgb(221, 221, 221);
        border-top: $border;
        border-bottom: $border;

    }
}

.current-slogan {
    font-size: 1.5em;
    font-weight: bold;
}

#logout {
    display: none;
}
