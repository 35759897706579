body {
  font-size: 16px;
}

ol {
  padding: 0;
}

main {
  text-align: center;
  width: 95%;
  margin: auto;
  list-style-position: inside;
}

header, footer {
  text-align: center;
}

.black-text {
  color: #000;
}

.hide {
  display: none;
}

.reveal {
  display: initial;
  opacity: 0;
}

.loading > * {
  display: none;
}

.loading #loading {
  display: initial;
  font-size: 2em;
}

#slogans ol, #editor-current-slogan ol {
  flex-direction: column;
  gap: 15px;
  display: flex;
}

#slogans ol > *, #editor-current-slogan ol > * {
  border-top: 1px groove #ddd;
  border-bottom: 1px groove #ddd;
  width: 100%;
  min-height: 5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.current-slogan {
  font-size: 1.5em;
  font-weight: bold;
}

#logout {
  display: none;
}
/*# sourceMappingURL=index.19a4d14b.css.map */
